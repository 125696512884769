import {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {useAuth} from "../../contexts/authContext";
import ProtectedRoute from "../../contexts/ProtectedRoute";
import Page403 from "../../pages/Page403";
import AdvanceRequest from "../../pages/partner/AdvanceRequest";
import PartnerRegister from "../../pages/partner/PartnerRegister";
import {ROLE_ADMIN, ROLE_COLLABORATOR, ROLE_COLLABORATOR_VIP, ROLE_USER, ROLE_USER_VIP} from "../../utils/constants";
import {Login} from "@mui/icons-material";
import CommissionRequest from "../../pages/partner/CommissionRequest";
import TransactionHistories from "../../pages/admin/TransactionHistories";
import AdvancePayment from "../../pages/partner/AdvancePayment";
import DepositMoney from "../../pages/partner/DepositMoney";
import ChargeDiamond from "../../pages/partner/ChargeDiamond";

const RequestManagement = lazy(() => import("../../pages/admin/RequestManagement"));
const ApproveRequest = lazy(() => import("../../pages/admin/ApproveRequest"));
const ApproveCommissionRequest = lazy(() => import("../../pages/admin/ApproveCommissionRequest"));
const AdvanceManagement = lazy(() => import("../../pages/admin/AdvanceManagement"));
const CommissionManagement = lazy(() => import("../../pages/admin/CommissionManagement"));
const UserProfile = lazy(() => import("../../pages/UserProfile"));
const LockUser = lazy(() => import("../../pages/admin/LockUser"));
const LazyListUsers = lazy(() => import("../../pages/admin/ListUsers"));

const RoleBasedRoutes = () => {
    const {role} = useAuth();

    if (!role) {
        return (
            <Routes>
                <Route path="/login" element={<Login/>}/>
            </Routes>
        );
    }

    return (
        <Routes>
            {role === ROLE_ADMIN && (
                <>
                    <Route path="/advance/create-request" element={<AdvanceRequest/>}/>
                    <Route path="/user-profile" element={<UserProfile/>}/>
                    <Route path="/partner-register" element={<PartnerRegister/>}/>
                    <Route path="/admin/request-management" element={<RequestManagement/>}/>
                    <Route path="/admin/approve-request/:id" element={<ApproveRequest/>}/>
                    <Route path="/admin/approve-commission/:id" element={<ApproveCommissionRequest/>}/>
                    <Route path="/admin/advance-management" element={<AdvanceManagement/>}/>
                    <Route path="/admin/commission-management" element={<CommissionManagement/>}/>
                    <Route path="/admin/list-users" element={<LazyListUsers/>}/>
                    <Route path="/admin/lock-action/:id" element={<LockUser/>}/>
                    <Route path="/admin/transaction-histories" element={<TransactionHistories/>}/>
                    <Route path="/charge-diamond" element={<ChargeDiamond/>}/>
                </>
            )}

            {(role === ROLE_COLLABORATOR || role === ROLE_COLLABORATOR_VIP) && (
                <Route
                    element={<ProtectedRoute allowedRoles={[ROLE_COLLABORATOR, ROLE_ADMIN, ROLE_COLLABORATOR_VIP]}/>}
                >
                    {role === ROLE_COLLABORATOR_VIP && (
                        <Route path="/advance/create-request" element={<AdvanceRequest/>}/>
                    )}
                    <Route path="/user-profile" element={<UserProfile/>}/>
                    <Route path="/commission/request" element={<CommissionRequest/>}/>
                    <Route path="/advance-payment" element={<AdvancePayment/>}/>
                    <Route path="/deposit-money" element={<DepositMoney/>}/>
                    <Route path="/charge-diamond" element={<ChargeDiamond/>}/>
                </Route>
            )}

            {(role === ROLE_USER || role === ROLE_USER_VIP) && (
                <Route element={<ProtectedRoute allowedRoles={[ROLE_USER, ROLE_ADMIN, ROLE_USER_VIP]}/>}>
                    {role === ROLE_USER_VIP && <Route path="/advance/create-request" element={<AdvanceRequest/>}/>}
                    <Route path="/user-profile" element={<UserProfile/>}/>
                    <Route path="/partner-register" element={<PartnerRegister/>}/>
                    <Route path="/charge-diamond" element={<ChargeDiamond/>}/>
                </Route>
            )}

            <Route path="*" element={<Page403/>}/>
        </Routes>
    );
};

export default RoleBasedRoutes;
