import {CallApi} from "../config/CallApi";
import {url} from "../url";
import {HTTP_METHOD} from "../../utils/constants";

const transactionApi = {
    getDebtInfo: (successHandler, errorHandler) =>
        CallApi(
            url.getDebtInfo,
            HTTP_METHOD.GET,
            {},
            {},
            true,
            successHandler,
            errorHandler,
        ),
    getDepositInfo: (successHandler, errorHandler) =>
        CallApi(
            url.getDepositInfo,
            HTTP_METHOD.GET,
            {},
            {},
            true,
            successHandler,
            errorHandler,
        ),
    getTransactionHistories: (body, successHandler, errorHandler) =>
        CallApi(
            url.getTransactionHistories,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    chargeDiamond: (body, successHandler, errorHandler) =>
        CallApi(
            url.chargeDiamond,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
};

export default transactionApi;
