import { Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, TextField, Typography } from "@mui/material";
import { useAuth } from "../../contexts/authContext";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import advanceApi from "../../api/services/advanceApi";
import { messageService } from "../../services/messageService";

const AdvanceRequest = () => {
    const { username } = useAuth();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openApproveModal, setOpenApproveModal] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const form = document.getElementById("advanceForm");
        const formData = new FormData(form);
        const advanceMoney = formData.get("advanceMoney");
        if (advanceMoney && advanceMoney % 1000 !== 0) {
            setError(true);
            setOpenApproveModal(false);
            setLoading(false);
            return;
        } else {
            setError(false);
        }
        const rawData = {
            username: username,
            advanceMoney: advanceMoney
        }
        advanceApi.createRequest(rawData, handleCreateRequestSuccess, showError);
    }

    const handleCreateRequestSuccess = (response) => {
        messageService.showMessage("Tạo yêu cầu tạm ứng thành công", "success");
        setLoading(false);
        setOpenApproveModal(false);
    }

    const showError = (error) => {
        const message = error?.response?.data?.message || "Có lỗi xảy ra";
        messageService.showMessage(message, "error");
        setOpenApproveModal(false);
        setLoading(false);
    }

    return (
        <Container fixed>
            <Card sx={{
                marginTop: '100px',
                marginBottom: '100px',
            }}>
                <Typography variant="h5" color="text.primary" fontWeight={'700'} sx={{ marginBottom: '24px' }}>
                    Yêu cầu tạm ứng
                </Typography>
                <form id="advanceForm">
                    <InputLabel id="money-label">Số tiền bạn muốn ứng trước:</InputLabel>
                    <TextField
                        id="money-input"
                        variant="outlined"
                        fullWidth
                        type="number"
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            },
                            input: {
                                endAdornment: 'VND',
                            },
                            htmlInput: {
                                step: 1000,
                                min: 10000,
                                defaultValue: 10000
                            }
                        }}
                        error={error}
                        helperText={error ? "Số tiền ứng phải chia hết cho 1000" : ""}
                        placeholder='Nhập số tiền bạn muốn tạm ứng'
                        autoComplete='advanceMoney'
                        name="advanceMoney"
                    />
                    <Typography variant="p" fontStyle={"italic"} fontWeight={550} color="text.secondary">
                        - Số tiền tạm ứng tối đa là: x triệu
                    </Typography>
                    <br/>
                    <Typography variant="p" fontStyle={"italic"} fontWeight={550} color="text.secondary">
                        - Số tiền tạm ứng mỗi lần không vượt quá số tiền tạm ứng còn lại trong tài khoản (Số tiền tạm ứng còn lại = x triệu - Số tiền tạm ứng bạn đã nhận)
                    </Typography>
                    <br/>
                    <br/>
                    <Button onClick={() => setOpenApproveModal(true)} variant='contained' fullWidth>Gửi yêu cầu</Button>

                    <Dialog open={openApproveModal} onClose={() => setOpenApproveModal(false)}>
                        <DialogTitle>Xác nhận
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenApproveModal(false)}
                                sx={{ position: 'absolute', right: 8, top: 8 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{ width: { xs: '300px', md: '400px' } }}>
                            <DialogContentText>
                                Xác nhận gửi yêu cầu?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenApproveModal(false)}>Hủy</Button>
                            <LoadingButton loading={loading} onClick={handleSubmit} variant='contained'>Xác nhận</LoadingButton>
                        </DialogActions>
                    </Dialog>
                </form>
            </Card>
        </Container >
    )
}

export default AdvanceRequest;