import React, {useEffect, useState} from 'react';
import ModalQR from "../../components/ui/modals/ModalQR";
import {Container, Typography} from "@mui/material";
import settingApi from "../../api/services/transactionApi";

const AdvancePayment = () => {
    const [settings, setSettings] = useState({});

    useEffect(() => {
        settingApi.getDebtInfo(handleGetSettingSuccess, showError);
    }, []);

    const handleGetSettingSuccess = (dataRes) => {
        console.log(dataRes)
        const modifiedSettings = {
            bank: dataRes.bankName,
            accountName: dataRes.accountName,
            accountNumber: dataRes.accountNumber,
            content: dataRes.content,
        }

        setSettings(modifiedSettings)
    }
    const showError = (e) => {
        console.log(e)
    }

    return (
        <Container sx={{my: 2, p: 3, backgroundColor: "#fff", borderRadius: "10px"}}>
            <Typography variant="h5" gutterBottom sx={{fontWeight: "bold"}}>
                Thanh toán tiền tạm ứng
            </Typography>
            <ModalQR info={settings}/>
        </Container>
    );
};

export default AdvancePayment;