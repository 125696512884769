import { CallApi } from "../config/CallApi";
import { url } from "../url";
import { HTTP_METHOD } from "../../utils/constants";

const authApi = {
  login: (body, successHandler, errorHandler) =>
    CallApi(
      url.login,
      HTTP_METHOD.POST,
      {},
      body,
      false,
      successHandler,
      errorHandler,
    ),
  register: (body, successHandler, errorHandler) =>
    CallApi(
      url.register,
      HTTP_METHOD.POST,
      {},
      body,
      false,
      successHandler,
      errorHandler,
    ),
};

export default authApi;
