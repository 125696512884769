import React from 'react';
import {Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

const MultipleSelect = ({elements, selectedElements = [], onChangeSelect, label}) => {
    return (

        <div>
            <FormControl style={{width: 200}}>
                <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedElements}
                    onChange={onChangeSelect}
                    input={<OutlinedInput label={label}/>}
                    renderValue={(selected) => {
                        return selected
                            .map((value) => elements.find((item) => item.value === value)?.label)
                            .filter(Boolean)
                            .join(', ');
                    }}
                    fullWidth
                    variant={"outlined"}
                >
                    {elements.map((e) => (
                        <MenuItem key={e.value} value={e.value}>
                            <Checkbox checked={selectedElements.includes(e.value)}/>
                            <ListItemText primary={e.label}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultipleSelect;