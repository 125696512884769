import {
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import { useAuth } from "../../contexts/authContext";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { messageService } from "../../services/messageService";
import userApi from "../../api/services/userApi";
import { appMap, fieldNames } from "../../utils/constants";
import { validateObject } from "../../utils/validates";
import commissionApi from "../../api/services/commissionApi";

const CommissionRequest = () => {
    const { username } = useAuth();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [userData, setUserData] = useState({});
    const [commission, setCommission] = useState(50000);
    const handleChangeOption = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        const fectchUser = () => {
            userApi.userDetail(username, handleGetUserSuccess, showError);
        };
        fectchUser();
    }, [username]);

    const handleGetUserSuccess = (response) => {
        setUserData(response);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const form = document.getElementById("commissionForm");
        const formData = new FormData(form);
        const requestReceiveMoney = formData.get("requestReceiveMoney");
        if (requestReceiveMoney && requestReceiveMoney % 1000 !== 0) {
            setErrors({ ...errors, commissionMoneyError: "Số tiền phải chia hết cho 1000" });
        }
        let rawData = {};
        if (selectedOption === "bankOption") {
            rawData = {
                formOfReceipt: "AMOUNT_RECEIVE_BANK",
                bankName: userData?.bankName,
                bankNumber: userData?.bankNumber,
            };
        } else {
            rawData = {
                formOfReceipt: "DIAMOND_RECEIVE_APP",
                appName: appMap[userData?.ctvApp],
                appUid: formData.get("userId"),
            };
        }
        let errorsField = validateObject(rawData, fieldNames);
        if (errorsField && Object.keys(errorsField).length > 0) {
            setErrors(errorsField);
            setLoading(false);
            return;
        } else {
            setErrors({});
        }
        const reqBody =
            selectedOption === "bankOption"
                ? { ...rawData, requestReceiveMoney: +requestReceiveMoney, appName: "", appUid: "" }
                : { ...rawData, requestReceiveMoney: +requestReceiveMoney, bankName: "", bankNumber: "" };
        commissionApi.createRequest(reqBody, handleCreateRequestSuccess, showError);
    };

    const handleCreateRequestSuccess = () => {
        messageService.showMessage("Tạo yêu cầu thành công", "success");
        setLoading(false);
        setOpenApproveModal(false);
    };

    const showError = () => {
        messageService.showMessage("Tạo yêu cầu thất bại", "error");
        setLoading(false);
    };

    return (
        <Container fixed>
            <Card
                sx={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                <Typography variant="h5" color="text.primary" fontWeight={"700"} sx={{ marginBottom: "24px" }}>
                    Yêu cầu nhận hoa hồng
                </Typography>
                <form id="commissionForm">
                    <InputLabel id="money-label">Số hoa hồng muốn nhận:</InputLabel>
                    <TextField
                        id="money-input"
                        variant="outlined"
                        fullWidth
                        type="number"
                        size="small"
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            },
                            input: {
                                endAdornment: "VND",
                            },
                            htmlInput: {
                                step: 1000,
                                min: 50000,
                                defaultValue: 50000,
                            },
                        }}
                        onChange={(e) => setCommission(e.target.value)}
                        error={!!errors.commissionMoneyError}
                        helperText={errors.commissionMoneyError}
                        placeholder="Nhập số tiền bạn muốn nhận"
                        autoComplete="requestReceiveMoney"
                        name="requestReceiveMoney"
                    />
                    <InputLabel id="money-label">Bạn muốn nhận hoa hồng qua hình thức nào?</InputLabel>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            name="commissionType"
                            onChange={handleChangeOption}
                            sx={{
                                marginBottom: selectedOption === "diamondOption" ? 0 : "20px",
                            }}
                        >
                            <FormControlLabel
                                value="bankOption"
                                control={<Radio />}
                                label="Chuyển khoản qua ngân hàng"
                            />
                            {selectedOption === "bankOption" && (
                                <Box sx={{ pl: 4 }}>
                                    <TextField
                                        name="bankName"
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Tên ngân hàng"
                                        aria-readonly
                                        value={userData?.bankName}
                                    />
                                    <TextField
                                        name="bankNumber"
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Số tài khoản"
                                        aria-readonly
                                        value={userData?.bankNumber}
                                    />
                                </Box>
                            )}
                            <FormControlLabel
                                value="diamondOption"
                                control={<Radio />}
                                label="Nhận kim cương qua app"
                            />
                            {selectedOption === "diamondOption" && (
                                <Box sx={{ pl: 4 }}>
                                    <TextField
                                        name="appName"
                                        value={appMap[userData?.ctvApp]}
                                        fullWidth
                                        variant="outlined"
                                        aria-readonly
                                    />
                                    <TextField
                                        name="userId"
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Nhập User ID"
                                        error={!!errors.userId}
                                        helperText={errors.userId}
                                    />
                                </Box>
                            )}
                        </RadioGroup>
                    </FormControl>

                    {selectedOption === "diamondOption" && (
                        <>
                            <InputLabel id="diamondAmount">Số kim cương tương ứng</InputLabel>
                            <TextField
                                id="diamondAmount"
                                name="diamondAmount"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                fullWidth
                                size="small"
                                variant="outlined"
                                aria-readonly
                                value={Math.round(commission / 250)}
                            />

                            <Box
                                flexGrow={"inherit"}
                                marginBottom={"20px"}
                                textAlign={"center"}
                                alignContent={"center"}
                            >
                                <Typography
                                    textAlign={"center"}
                                    variant="p"
                                    fontStyle={"italic"}
                                    fontWeight={550}
                                    color="text.secondary"
                                >
                                    Tỷ giá quy đổi: x đồng = 1 kim cương
                                </Typography>
                            </Box>
                        </>
                    )}
                    <Button onClick={() => setOpenApproveModal(true)} variant="contained" fullWidth>
                        Gửi yêu cầu
                    </Button>

                    <Dialog open={openApproveModal} onClose={() => setOpenApproveModal(false)}>
                        <DialogTitle>
                            Xác nhận
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenApproveModal(false)}
                                sx={{ position: "absolute", right: 8, top: 8 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{ width: { xs: "300px", md: "400px" } }}>
                            <DialogContentText>Xác nhận gửi yêu cầu?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenApproveModal(false)}>Hủy</Button>
                            <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">
                                Xác nhận
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                </form>
            </Card>
        </Container>
    );
};

export default CommissionRequest;
