import React, {useEffect, useState} from 'react';
import {Stack, Typography} from "@mui/material";

const ModalQR = ({info}) => {
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (info) {
            setSettings(info);
        }
    }, [info]);

    return (
        <Stack direction={"column"} spacing={3} sx={{width: "100%"}} justifyContent={"center"} alignItems={"center"}>
            <img
                style={{maxWidth: "250px"}}
                alt={""}
                src={`https://img.vietqr.io/image/${settings?.bank}-${settings?.accountNumber}-qr_only.jpg?addInfo=${settings?.content}&accountName=${settings?.accountName}`}
            />
            <Stack direction={"column"} spacing={2} sx={{width: "100%", border: "solid 1px #000", p: 2}}
                   justifyContent={"center"}
                   alignItems={"center"}>
                <Typography variant="h6" gutterBottom sx={{fontWeight: "bold"}}>
                    Thông tin chuyển khoản
                </Typography>
                <Stack direction={"column"} spacing={2} justifyContent={"left"} sx={{width: "100%"}}>
                    <Typography variant="subtitle1" gutterBottom sx={{fontWeight: "bold"}}>
                        CTK: {settings?.bank}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{fontWeight: "bold"}}>
                        STK: {settings?.accountNumber}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{fontWeight: "bold"}}>
                        NỘI DUNG: {settings?.content}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ModalQR;