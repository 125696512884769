import { JSEncrypt } from "jsencrypt";

const encryptHelper = {
  encryptPassword: (password) => {
    const publicKey = `-----BEGIN PUBLIC KEY-----
            ${process.env.REACT_APP_RSA_PUBLIC_KEY}
            -----END PUBLIC KEY-----`;
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    return encrypt.encrypt(password);
  },
};

export default encryptHelper;
