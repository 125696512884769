import React, { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { messageService } from "../services/messageService";

const MessageProvider = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    messageService.setMessage((msg, severityType) => {
      setMessage(msg);
      setSeverity(severityType);
      setOpen(true);
    });
  }, []);

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessageProvider;
