import {CONSTANTS, HTTP_METHOD} from "../../utils/constants";
import axiosClient from "./axiosClient";

const fetch = (url, method, config, body) => {
    switch (method) {
        case HTTP_METHOD.GET:
            return axiosClient.get(url, config)
        case HTTP_METHOD.POST:
            return axiosClient.post(url, body, config)
        case HTTP_METHOD.PUT:
            return axiosClient.put(url, body, config)
        case HTTP_METHOD.DELETE:
            return axiosClient.delete(url, config)
        default:
            break;
    }
}

function handleUnAuthorizeError(params, tokenNeeded, url, method, body, successHandler, errorHandler) {
    window.location.href = "/login";
    localStorage.clear()
}

export const CallApi = (
    url,
    method,
    params,
    body,
    tokenNeeded,
    successHandler,
    errorHandler,
) => {
    let config = {params}
    const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);
    if (tokenNeeded && token) {
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        config = {...config, headers}
    }

    new Promise((resolve, reject) => {
        fetch(url, method, config, body)
            .then(response => {
                return response;
            })
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    })
        .then(data => {
            successHandler(data.data);
        })
        .catch(error => {
            if (error && error.response && error.response.status === 401) {
                handleUnAuthorizeError(params, tokenNeeded, url, method, body, successHandler, errorHandler);
            }
            errorHandler(error);
        });
}