import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import RoleBasedRoutes from "./components/auth/RoleBasedRoutes";
import LoadingComponent from "./components/common/LoadingComponent";
import { AuthProvider } from "./contexts/authContext";
import MessageProvider from "./contexts/messageContext";
import Login from "./pages/Login";
import Register from "./pages/Register";
import theme from "./theme/theme";

const Layout = lazy(() => import("./components/layout/Layout"));

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <MessageProvider />
                <AuthProvider>
                    <Router>
                        <CssBaseline />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Layout />
                                    </Suspense>
                                }
                            >
                                {/* Public Routes */}
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />

                                {/* Protected Routes */}
                                <Route path="*" element={<RoleBasedRoutes />} />
                            </Route>
                        </Routes>
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
