import {CallApi} from "../config/CallApi";
import {url} from "../url";
import {HTTP_METHOD} from "../../utils/constants";

const userApi = {
    userDetail: (param, successHandler, errorHandler) =>
        CallApi(
            url.userDetail,
            HTTP_METHOD.GET,
            {
                username: param
            },
            null,
            true,
            successHandler,
            errorHandler,
        ),
    partnerRegister: (body, successHandler, errorHandler) =>
        CallApi(
            url.partnerRegister,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    getListUsers: (body, successHandler, errorHandler) =>
        CallApi(
            url.getListUsers,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    changeWalletMoney: (body, successHandler, errorHandler) =>
        CallApi(
            url.changeWalletMoney,
            HTTP_METHOD.PUT,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    checkPartnerRegister: (successHandler, errorHandler) =>
        CallApi(
            url.checkPartnerRegister,
            HTTP_METHOD.GET,
            {},
            null,
            true,
            successHandler,
            errorHandler,
        ),
    updateUserProfile: (body, successHandler, errorHandler) =>
        CallApi(
            url.updateUserProfile,
            HTTP_METHOD.PUT,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    lockUser: (body, successHandler, errorHandler) =>
        CallApi(
            url.lockUser,
            HTTP_METHOD.PUT,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    validateAppUid: (body, successHandler, errorHandler) =>
        CallApi(
            url.validateAppUid,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
};

export default userApi;
