import { CallApi } from "../config/CallApi";
import { url } from "../url";
import { HTTP_METHOD } from "../../utils/constants";

const commissionApi = {
    createRequest: (body, successHandler, errorHandler) =>
        CallApi(
            url.commissionRequest,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    viewAllRequest: (body, successHandler, errorHandler) =>
        CallApi(
            url.viewAllCommissionRequest,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    commissionAction: (body, successHandler, errorHandler) =>
        CallApi(
            url.commissionAdminAction,
            HTTP_METHOD.PUT,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    commissionDetail: (param, successHandler, errorHandler) =>
        CallApi(
            url.commissionDetail + `/${param}`,
            HTTP_METHOD.GET,
            {},
            null,
            true,
            successHandler,
            errorHandler,
        ),
};

export default commissionApi;
