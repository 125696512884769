import { CallApi } from "../config/CallApi";
import { url } from "../url";
import { HTTP_METHOD } from "../../utils/constants";

const advanceApi = {
    createRequest: (body, successHandler, errorHandler) =>
        CallApi(
            url.advanceRequest,
            HTTP_METHOD.POST,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
    viewAllRequest: (params, successHandler, errorHandler) =>
        CallApi(
            url.viewAllRequest,
            HTTP_METHOD.GET,
            {
                status: params.status || 'PENDING_FOR_APPROVAL',
                pageNo: params.pageNo || 0,
                pageSize: params.pageSize || 5,
            },
            null,
            true,
            successHandler,
            errorHandler,
        ),
    advanceAction: (body, successHandler, errorHandler) =>
        CallApi(
            url.advanceAdminAction,
            HTTP_METHOD.PUT,
            {},
            body,
            true,
            successHandler,
            errorHandler,
        ),
};

export default advanceApi;
