import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Container, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateRequired } from "../utils/validates";
import encryptHelper from "../helpers/encryptHelper";
import authApi from "../api/services/authApi";
import { useAuth } from "../contexts/authContext";
import { messageService } from "../services/messageService";
import { CONSTANTS } from "../utils/constants";

const Login = () => {
    const { login, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);
        if (token) {
            logout();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target);
        const username = formData.get('username');
        const password = formData.get('password');
        const usernameError = validateRequired(username, 'Username');
        const passwordError = validateRequired(password, 'Password');

        if (usernameError || passwordError) {
            setErrors({ username: usernameError, password: passwordError });
            setLoading(false);
            return;
        }
        else {
            setErrors({});
        }
        const body = {
            loginName: encryptHelper.encryptPassword(username),
            loginPassword: encryptHelper.encryptPassword(password)
        }
        authApi.login(body, handleCallLoginSuccess, showError);
    }

    const handleCallLoginSuccess = (data) => {
        messageService.showMessage("Đăng nhập thành công!", "success");
        setLoading(false);
        login(data.token);
        navigate("/user-profile")
    };

    const showError = (error) => {
        const message = error?.response?.data?.message || "Có lỗi xảy ra";
        messageService.showMessage(message, "error");
        setLoading(false);
    };

    return (
        <Container fixed>
            <Card sx={{
                marginTop: '100px',
                marginBottom: '100px',
                textAlign: 'center',
                alignItems: 'center'
            }}>
                <Box
                    component={'img'}
                    sx={{
                        width: { xs: '60%', sm: '35%', md: '30%', lg: '25%' },
                        textAlign: 'center',
                        margin: 'auto',
                        aspectRatio: '1 / 1',
                        marginBottom: '36px',
                        marginTop: '20px',
                    }}
                    src="/logo-placeholder.png" />
                <form onSubmit={handleLogin}>
                    <TextField
                        id="username-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        name="username"
                        placeholder='Username/Email/SĐT'
                        autoComplete='username'
                        error={!!errors.username}
                        helperText={errors.username}
                    />
                    <TextField
                        id="password-input"
                        variant="outlined"
                        fullWidth
                        size='small'
                        slotProps={{
                            inputLabel: {
                                shrink: false
                            }
                        }}
                        type="password"
                        name="password"
                        placeholder='Mật khẩu'
                        autoComplete='password'
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <LoadingButton loading={loading} type='submit' variant='contained' fullWidth>Đăng nhập</LoadingButton>
                </form>
                <Box display={'flex'} alignContent={'center'} marginTop={'10px'} justifyContent={'center'}>
                    <Button
                        component={Link}
                        sx={{
                            textTransform: 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                                backgroundColor: 'unset'
                            },
                        }}
                        to={'/register'}
                    >
                        <Typography variant='overlines'>Đăng ký</Typography>
                    </Button>
                    <Box sx={{
                        paddingTop: '8px',
                    }}>
                        <Typography variant='overlines' fontWeight={600}>|</Typography>
                    </Box>
                    <Button
                        component={Link}
                        sx={{
                            textTransform: 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                                backgroundColor: 'unset'
                            },
                        }}
                        to={'/'}
                    >
                        <Typography variant='overlines'>Quên mật khẩu?</Typography>
                    </Button>
                </Box>
            </Card>
        </Container>
    )
}

export default Login;