import {LoadingButton} from "@mui/lab";
import {
    Card,
    Container,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CONSTANTS, fieldNames, REGEX_PHONE_NUMBER} from "../utils/constants";
import {useAuth} from "../contexts/authContext";
import {validateObject, validateRegex, validateRePassword} from "../utils/validates";
import authApi from "../api/services/authApi";
import encryptHelper from "../helpers/encryptHelper";
import {messageService} from "../services/messageService";
import {useNavigate} from "react-router-dom";
import ModalValidateUserId from "../components/ui/modals/ModalValidateUserId";

function Register() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [appSelect, setAppSelect] = useState("");
    const [errors, setErrors] = useState({});
    const [openValidateUidModal, setOpenValidateUidModal] = useState(false);
    const [appUid, setAppUid] = useState("");

    const {logout} = useAuth();

    useEffect(() => {
        const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);
        if (token) {
            logout("/register");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegister = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(e.target);
        let rawData = {
            username: formData.get("username"),
            phoneNumber: formData.get("phoneNumber"),
            password: formData.get("password"),
            appUId: appUid,
            app: appSelect,
        };

        let errorsField = validateObject(rawData, fieldNames);

        const noMatchPassword = validateRePassword(rawData.password, formData.get("rePassword"));
        if (noMatchPassword) {
            errorsField = {...errorsField, rePassword: noMatchPassword};
        }

        const phoneError = validateRegex(
            REGEX_PHONE_NUMBER,
            formData.get("phoneNumber"),
            "Số điện thoại không đúng định dạng. Vui lòng nhập số điện thoại hợp lệ với 10 đến 11 chữ số."
        );
        if (phoneError) {
            errorsField = {...errorsField, phoneNumber: phoneError};
        }

        if (errorsField && Object.keys(errorsField).length > 0) {
            setErrors(errorsField);
            setLoading(false);
            return;
        } else {
            setErrors({});
            const password = encryptHelper.encryptPassword(rawData.password);
            rawData = {
                ...rawData,
                password: password,
                rePassword: password,
            };
        }
        authApi.register(rawData, handleRegisterSuccess, showError);
    };

    const handleRegisterSuccess = (response) => {
        messageService.showMessage("Đăng ký thành công!", "success");
        setLoading(false);
        navigate("/login");
    };
    const showError = (error) => {
        messageService.showMessage(error?.response?.data?.message, "error");
        setLoading(false);
    };

    const handleAppSelect = (e) => {
        e.preventDefault();
        setAppSelect(e.target.value);
        setOpenValidateUidModal(true);
    };

    const handleSetValue = (value) => {
        setAppUid(value); // Set the selected UID
    };

    return (
        <Container fixed>
            <Card
                sx={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                <Typography variant="h5" color="text.primary" fontWeight={"700"} sx={{marginBottom: "24px"}}>
                    Đăng ký tài khoản
                </Typography>
                <form onSubmit={handleRegister}>
                    <InputLabel required id="username-label">
                        Username
                    </InputLabel>
                    <TextField
                        id="username-input"
                        variant="outlined"
                        fullWidth
                        size="small"
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            },
                        }}
                        placeholder="Username"
                        autoComplete="username"
                        name="username"
                        error={!!errors.username}
                        helperText={errors.username}
                    />
                    <InputLabel id="app-select-label">App</InputLabel>
                    <FormControl fullWidth variant="outlined" margin="none">
                        <Select
                            id="app-select"
                            fullWidth
                            value={appSelect}
                            displayEmpty
                            inputProps={{
                                "aria-label": "Without label",
                            }}
                            sx={{
                                marginBottom: errors.app ? 0 : "20px",
                            }}
                            onChange={handleAppSelect}
                            error={!!errors.app}
                        >
                            <MenuItem value={"PATI"}>Pati</MenuItem>
                            <MenuItem value={"MICO"}>Mico</MenuItem>
                        </Select>
                        <FormHelperText
                            error={!!errors.app}
                            sx={{
                                marginBottom: errors.app ? '20px' : 0,
                            }}
                        >
                            {errors.app}
                        </FormHelperText>
                    </FormControl>
                    <InputLabel required id="userID-label">
                        User ID trên app
                    </InputLabel>
                    <TextField
                        id="userID-input"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={appUid}
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            },
                        }}
                        placeholder="User ID"
                        autoComplete="userID"
                        name="appUId"
                        error={!!errors.userID}
                        helperText={errors.userID}
                        disabled={true}
                    />
                    <InputLabel required id="phone-label">
                        Số điện thoại
                    </InputLabel>
                    <TextField
                        id="phone-input"
                        variant="outlined"
                        fullWidth
                        size="small"
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            },
                        }}
                        placeholder="Số điện thoại"
                        autoComplete="tel"
                        name="phoneNumber"
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber}
                    />
                    <InputLabel required id="password-label">
                        Mật khẩu của bạn
                    </InputLabel>
                    <TextField
                        id="password-input"
                        variant="outlined"
                        fullWidth
                        size="small"
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            },
                        }}
                        type="password"
                        placeholder="Mật khẩu của bạn"
                        autoComplete="new-password"
                        name="password"
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <InputLabel required id="re-password-label">
                        Nhập lại mật khẩu
                    </InputLabel>
                    <TextField
                        id="re-password-input"
                        variant="outlined"
                        fullWidth
                        size="small"
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            },
                        }}
                        type="password"
                        placeholder="Nhập lại mật khẩu"
                        autoComplete="new-password"
                        name="rePassword"
                        error={!!errors.rePassword}
                        helperText={errors.rePassword}
                    />
                    <LoadingButton loading={loading} type="submit" variant="contained" fullWidth>
                        Đăng ký
                    </LoadingButton>
                </form>
            </Card>
            <ModalValidateUserId ctvApp={appSelect} openValidateUidModal={openValidateUidModal}
                                 setValidateUidModal={setOpenValidateUidModal} handleSetValue={handleSetValue}/>
        </Container>
    );
}

export default Register;
