import { jwtDecode } from "jwt-decode";
import React, {
    createContext,
    useContext,
    useState
} from "react";
import { CONSTANTS } from "../utils/constants";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState(() => {
        try {
            const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);
            let role = null;
            let username = null;
            if (token) {
                const decodedToken = jwtDecode(token);
                role = decodedToken.ROLE;
                username = decodedToken.sub;
            }
            return {
                isAuthenticated: !!token,
                role: role,
                username: username,
            };
        } catch (e) {
            localStorage.clear();
        }
    });

    const login = (token) => {
        localStorage.setItem(CONSTANTS.ACCESS_TOKEN, token);
        const decodedToken = jwtDecode(token);
        const role = decodedToken.ROLE;
        const username = decodedToken.sub;
        setAuthState({ isAuthenticated: true, role: role, username: username });
    };

    const logout = (url = "/login") => {
        localStorage.removeItem(CONSTANTS.ACCESS_TOKEN);
        setAuthState({ isAuthenticated: false, role: null });
        window.location.href = url;
    };

    return (
        <AuthContext.Provider value={{ ...authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
