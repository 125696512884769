export const CONSTANTS = {
    ACCESS_TOKEN: "access_token",
    WHITE_LIST_URL: [
        "/auth/register",
        "/auth/login",
        // , '/user/search'
    ],
    SUCCESS: "success",
    ERROR: "error",
};

export const HTTP_METHOD = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
};

export const ROLE_COLLABORATOR = "ROLE_COLLABORATOR";

export const ROLE_COLLABORATOR_VIP = "ROLE_COLLABORATOR_VIP";

export const ROLE_USER = "ROLE_USER";

export const ROLE_USER_VIP = "ROLE_USER_VIP";

export const ROLE_OFFICE = "ROLE_OFFICE";

export const ROLE_ADMIN = "ROLE_ADMIN";

export const RECHARGE_ACTION = "R";

export const WITHDRAW_ACTION = "W";

export const DIAMOND_RECEIVE_APP = "DIAMOND_RECEIVE_APP";

export const AMOUNT_RECEIVE_BANK = "AMOUNT_RECEIVE_BANK";

export const MESSAGE_TYPES = ["success", "info", "warning", "error"];

export const REGEX_PHONE_NUMBER = /^(\\+84|0)[1-9][0-9]{8,9}$/;

export const REGEX_IDENTIFY = /^\d{12}$/;

export const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const fieldNames = {
    fullName: "Họ & Tên",
    email: "Địa chỉ email",
    username: "Username",
    phoneNumber: "Số điện thoại",
    password: "Mật khẩu",
    identifyCard: "CCCD",
    bankName: "Tên ngân hàng",
    bankNumber: "Số tài khoản",
    app: "App",
    dateOfBirth: "Ngày sinh",
    gender: "Giới tính",
}

export const statusMap = {
    PENDING_FOR_APPROVAL: {
        title: "Chờ phê duyệt",
        color: "#0f86b6"
    },
    APPROVED: {
        title: "Đã phê duyệt",
        color: "green"
    },
    REJECTED: {
        title: "Từ chối",
        color: "red"
    },
};

export const roleMap = {
    ROLE_COLLABORATOR_VIP: "CTV Vip",
    ROLE_USER: "User",
    ROLE_ADMIN: "Admin",
    ROLE_COLLABORATOR: "CTV",
    ROLE_USER_VIP: "User Vip",
};

export const appMap = {
    PATI: "Pati",
    MICO: "Mico"
};

export const genderMap = {
    male: "Nam",
    female: "Nữ"
};