import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const TableDefault = ({ columns = [], rows = [], rowCount, onPaginationModelChange, onRowClick, columnVisibilityModel}) => {
    return (
      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10, 20]}
          pagination
          rowCount={rowCount}
          onPaginationModelChange={onPaginationModelChange}
          paginationMode="server"
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          initialState={{
              pagination: {
                paginationModel: { pageSize: 5 },
              },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onRowClick={onRowClick}
        />
      </Box>
    );
  };
  
  export default TableDefault;
  