import React, {useEffect, useState} from 'react';
import {Box, Button, Container, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MultipleSelect from "../../components/ui/multipleSelect/MultipleSelect";
import SearchIcon from "@mui/icons-material/Search";
import TableDefault from "../../components/ui/table/TableDefault";
import transactionApi from "../../api/services/transactionApi";
import dayjs from "dayjs";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DragHandleIcon from '@mui/icons-material/DragHandle';

let theme = createTheme({
    palette: {
        primary: {
            main: '#0A4D68',
            light: '#088395',
            dark: '#052A40',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#088395',
            light: '#05BFDB',
            dark: '#066A74',
            contrastText: '#FFFFFF',
        },
        background: {
            default: '#F5F5F5',
            paper: '#FFFFFF',
        },
        text: {
            primary: '#0A4D68',
            secondary: '#088395',
        },
    },
});

const listAppName = [
    {label: "Pati", value: "PATI"},
    {label: "Mico", value: "MICO"},
];

const listRole = [
    {label: "CTV", value: "ROLE_COLLABORATOR"},
    {label: "CTV Vip", value: "ROLE_COLLABORATOR_VIP"},
    {label: "User", value: "ROLE_USER"},
    {label: "User Vip", value: "ROLE_USER_VIP"},
];

const transactionType = [{label: "Nạp tiền", value: "DEPOSIT"}, {label: "Nhận hoa hồng", value: "GAIN_COMMISSION"},];

const TransactionHistories = () => {
    const [searchItems, setSearchItems] = useState({
        appList: [],
        roleList: [],
        transactionTypeList: [],
        searchText: ""
    });
    const [rowCount, setRowCount] = useState(0);
    const [pageData, setPageData] = useState({pageNo: 0, pageSize: 5});
    const [rows, setRows] = useState([]);

    useEffect(() => {
        handleSearchTransactionHistories();
    }, []);

    const columns = [
        {
            field: "index",
            headerName: "STT",
            flex: 0.1,
            headerAlign: 'center',
            align: "center",
        },
        {
            field: "transactionDate",
            headerName: "Thời gian giao dịch",
            flex: 1,
            headerAlign: 'center',
            align: "center",
            renderCell: (params) => {
                return params.value ? dayjs(params.value).format("DD/MM/YYYY hh:mm:ss") : "-";
            }
        },
        {
            field: "fullName", headerName: "Họ tên", flex: 1, headerAlign: 'center', align: "center",
        },
        {field: "username", headerName: "Username", flex: 0.75, headerAlign: 'center', align: "center",},
        {field: "app", headerName: "App", flex: 0.5, headerAlign: 'center', align: "center",},
        {field: "role", headerName: "Role", flex: 0.75, headerAlign: 'center', align: "center",},
        {field: "transactionType", headerName: "Hình thức giao dịch", flex: 1, headerAlign: 'center', align: "center",},
        {
            field: "balance",
            headerName: "Tiền trong tài khoản",
            flex: 1,
            headerAlign: 'center',
            align: "center",
            renderCell: (params) => renderMoneyStatus(params.value),
        },
        {
            field: "moneyCommission",
            headerName: "Tiền hoa hồng",
            flex: 1,
            headerAlign: 'center',
            align: "center",
            renderCell: (params) => renderMoneyStatus(params.value),
        },
        {
            field: "amountOfDebt",
            headerName: "Tiền tạm ứng",
            flex: 1,
            headerAlign: 'center',
            align: "center",
            renderCell: (params) => renderMoneyStatus(params.value),
        },
    ];

    const renderMoneyStatus = ({oldValue, newValue, status}) => {
        let statusIcon = null;
        if (status === "INCREASE") {
            statusIcon =
                <ArrowUpwardIcon sx={{backgroundColor: "green", color: "#fff", borderRadius: 1, fontSize: 16}}/>;
        } else if (status === "DECREASE") {
            statusIcon = <ArrowDownwardIcon
                sx={{backgroundColor: "red", color: "#fff", borderRadius: 1, fontSize: 16}}/>;
        } else {
            statusIcon = <DragHandleIcon
                sx={{backgroundColor: "#717179", color: "#fff", borderRadius: 1, fontSize: 16}}/>;
        }
        return (
            <Box>
                <Typography variant={"body2"}
                            sx={{fontSize: 12, opacity: "60%"}}>{oldValue?.toLocaleString()}đ</Typography>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
                    {statusIcon}
                    <Typography variant={"body1"}> {newValue?.toLocaleString()}đ</Typography>
                </Stack>
            </Box>);
    }

    const handleAppSelect = (event) => {
        const {target: {value}} = event;
        setSearchItems({...searchItems, appName: value});
    }

    const handleSelectRole = (event) => {
        const {
            target: {value},
        } = event;
        setSearchItems({...searchItems, role: value});
    }

    const handleSelectStatus = (event) => {
        const {
            target: {value},
        } = event;
        setSearchItems({...searchItems, status: value});
    }

    const handleInput = (event) => {
        const {
            target: {value},
        } = event;
        setSearchItems({...searchItems, input: value});
    }

    function handleGetHistoriesSuccess(res) {
        setRows(res.content.map((item, index) => ({
            id: index,
            index: index + 1,
            ...item
        })));
        setPageData({
            pageNo: res?.pageable?.pageNumber,
            pageSize: res?.pageable?.pageSize,
        });
        setRowCount(res?.totalElements);
    }

    function handleGetHistoriesFail(error) {
        console.log(error);
    }

    const handleSearchTransactionHistories = () => {
        const reqBody = {
            appList: searchItems.appList,
            roleList: searchItems.roleList,
            transactionTypeList: searchItems.transactionTypeList,
            searchText: searchItems.searchText,
            pageNo: pageData?.pageNo,
            pageSize: pageData?.pageSize,
        }

        transactionApi.getTransactionHistories(reqBody, handleGetHistoriesSuccess, handleGetHistoriesFail);
    }

    return (
        <Container maxWidth={"xl"} sx={{my: 2, p: 3, backgroundColor: "#fff", borderRadius: "10px"}}>
            <Typography variant="h4" gutterBottom sx={{fontWeight: "bold"}}>
                Lịch sử giao dịch
            </Typography>
            <Stack direction="row" spacing={2} sx={{mb: 2}} justifyContent={"space-between"}>
                <ThemeProvider theme={theme}>
                    <Stack direction={"row"} spacing={2}>
                        <MultipleSelect
                            label={"App name"}
                            elements={listAppName}
                            selectedElements={searchItems.appName}
                            onChangeSelect={handleAppSelect}
                        />
                        <MultipleSelect
                            label={"Role"}
                            elements={listRole}
                            selectedElements={searchItems.role}
                            onChangeSelect={handleSelectRole}
                        />
                        <MultipleSelect
                            label={"Hình thức giao dịch"}
                            elements={transactionType}
                            selectedElements={searchItems.status}
                            onChangeSelect={handleSelectStatus}
                        />
                    </Stack>
                </ThemeProvider>

                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <TextField
                        placeholder={"Search..."}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            },
                        }}
                        onChange={handleInput}
                    />
                    <Button variant={"contained"}>Search</Button>

                </Stack>
            </Stack>
            <TableDefault
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                columnVisibilityModel={{id: false}}
                // onPaginationModelChange={handlePaginationModalChange}
            />
        </Container>
    );
};

export default TransactionHistories;