import { LoadingButton } from "@mui/lab";
import {
    Card,
    Container,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userApi from "../../api/services/userApi";
import { useAuth } from "../../contexts/authContext";
import { messageService } from "../../services/messageService";
import { appMap, fieldNames, REGEX_IDENTIFY } from "../../utils/constants";
import { validateObject, validateRegex } from "../../utils/validates";

function PartnerRegister() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [gender, setGender] = useState("");
    const [userData, setUserData] = useState({});
    const [isRegistered, setIsRegistered] = useState(false);
    const [dob, setDob] = useState();
    const { username } = useAuth();

    useEffect(() => {
        userApi.checkPartnerRegister(handleCheckSuccess, showError);
    }, []);

    const handleCheckSuccess = (response) => {
        setIsRegistered(response);
    };

    useEffect(() => {
        const fectchUser = () => {
            userApi.userDetail(username, handleGetUserSuccess, showError);
        };
        fectchUser();
    }, [username]);

    const handleGetUserSuccess = (response) => {
        setUserData(response);
    };

    const handleRegister = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(e.target);
        let rawData = {
            fullName: formData.get("fullName"),
            dateOfBirth: formData.get("dob"),
            gender: gender,
            identifyCard: formData.get("identifyCard"),
            bankName: formData.get("bankName"),
            bankNumber: formData.get("bankNumber"),
            requestApp: userData?.ctvApp || "PATI",
        };

        let errorsField = validateObject(rawData, fieldNames);

        const indentifyError = validateRegex(REGEX_IDENTIFY, rawData?.identifyCard, "CCCD phải 12 chữ số");
        if (indentifyError) {
            errorsField = { ...errorsField, identifyCard: indentifyError };
        }

        if (errorsField && Object.keys(errorsField).length > 0) {
            setErrors(errorsField);
            setLoading(false);
            return;
        } else {
            setErrors({});
        }
        rawData = {
            ...rawData,
            dateOfBirth: new Date(rawData.dateOfBirth).toISOString()
        }
        userApi.partnerRegister(rawData, handleRegisterSuccess, showError);
    };

    const handleRegisterSuccess = (response) => {
        messageService.showMessage("Tạo yêu cầu nâng quyền thành công!", "success");
        setLoading(false);
        setTimeout(() => {
            navigate("/");
        }, 2000);
    };
    const showError = (error) => {
        const message = error?.response?.data?.message || "Có lỗi xảy ra";
        messageService.showMessage(message, "error");
        setLoading(false);
    };

    const handleGenderSelect = (e) => {
        e.preventDefault();
        setGender(e.target.value);
    };

    return (
        <Container fixed>
            <Card
                sx={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                {isRegistered ? (
                    <Typography variant="h6" color="text.primary" fontWeight={"700"}>
                        Bạn đã đăng ký thành công, đang chờ phê duyệt từ admin...
                    </Typography>
                ) : (
                    <>
                        <Typography variant="h5" color="text.primary" fontWeight={"700"} sx={{ marginBottom: "24px" }}>
                            Đăng ký tài khoản CTV
                        </Typography>
                        <form onSubmit={handleRegister}>
                            <InputLabel id="username-label">Username</InputLabel>
                            <TextField
                                id="username-input"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                value={userData?.username}
                                name="username"
                                disabled
                            />
                            <InputLabel id="app-select-label">App</InputLabel>
                            <TextField
                                id="app-selected"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                value={appMap[userData?.ctvApp]}
                                name="app"
                                disabled
                            />
                            <InputLabel id="userID-label">User ID trên app</InputLabel>
                            <TextField
                                id="appUid-input"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                value={userData?.appUid}
                                name="appUid"
                                disabled
                            />
                            <InputLabel id="phone-label">Số điện thoại</InputLabel>
                            <TextField
                                id="phone-input"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                value={userData?.phoneNumber}
                                placeholder="Số điện thoại"
                                autoComplete="tel"
                                name="phoneNumber"
                                disabled
                            />
                            <Typography
                                variant="h6"
                                color="text.primary"
                                fontWeight={"700"}
                                sx={{ marginBottom: "24px" }}
                            >
                                Thông tin cá nhân
                            </Typography>
                            <InputLabel required id="name-label">
                                Họ & Tên
                            </InputLabel>
                            <TextField
                                id="name-input"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                placeholder="Họ & Tên"
                                autoComplete="name"
                                name="fullName"
                                error={!!errors.fullName}
                                helperText={errors.fullName}
                            />
                            <InputLabel required id="gender-label">
                                Giới tính
                            </InputLabel>
                            <FormControl fullWidth variant="outlined" margin="none">
                                <Select
                                    id="gender-select"
                                    fullWidth
                                    value={gender}
                                    displayEmpty
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    sx={{
                                        marginBottom: errors.gender ? 0 : "20px",
                                    }}
                                    onChange={handleGenderSelect}
                                    error={!!errors.gender}
                                >
                                    <MenuItem value={"Male"}>Nam</MenuItem>
                                    <MenuItem value={"Female"}>Nữ</MenuItem>
                                </Select>
                                <FormHelperText
                                    error={!!errors.gender}
                                    sx={{
                                        marginBottom: errors.gender ? "20px" : 0,
                                    }}
                                >
                                    {errors.gender}
                                </FormHelperText>
                            </FormControl>
                            <InputLabel required id="dob-label">
                                Ngày sinh
                            </InputLabel>
                            <MobileDatePicker
                                value={dob}
                                name="dob"
                                onChange={(newValue) => setDob(newValue)}
                                disableHighlightToday
                                sx={{ width: "100%" }}
                                slotProps={{
                                    textField: {
                                        error: !!errors.dateOfBirth,
                                        helperText: errors.dateOfBirth,
                                    },
                                }}
                            />
                            <InputLabel required id="idCard-label">
                                CCCD của bạn
                            </InputLabel>
                            <TextField
                                id="idCard-input"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                placeholder="CCCD của bạn"
                                name="identifyCard"
                                error={!!errors.identifyCard}
                                helperText={errors.identifyCard}
                            />
                            <Typography
                                variant="inherit"
                                component="h2"
                                fontWeight="600"
                                color="secondary"
                                textAlign="center"
                                fontStyle="italic"
                            >
                                Vui lòng liên hệ qua Zalo dưới đây để gửi ảnh CCCD của bạn <br></br>
                                SĐT:
                            </Typography>
                            <InputLabel required id="bank-label">
                                Tên ngân hàng
                            </InputLabel>
                            <TextField
                                id="bank-input"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                placeholder="Tên ngân hàng"
                                name="bankName"
                                error={!!errors.bankName}
                                helperText={errors.bankName}
                            />
                            <InputLabel required id="bankNum-label">
                                Số tài khoản
                            </InputLabel>
                            <TextField
                                id="bankNum-input"
                                variant="outlined"
                                fullWidth
                                size="small"
                                slotProps={{
                                    inputLabel: {
                                        shrink: false,
                                    },
                                }}
                                placeholder="Số tài khoản"
                                name="bankNumber"
                                error={!!errors.bankNumber}
                                helperText={errors.bankNumber}
                            />
                            <LoadingButton loading={loading} type="submit" variant="contained" fullWidth>
                                Đăng ký
                            </LoadingButton>
                        </form>
                    </>
                )}
            </Card>
        </Container>
    );
}

export default PartnerRegister;
