import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Page403 = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate("/");
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                textAlign: "center",
            }}
        >
            <Typography variant="h1" color="error" gutterBottom>
                403
            </Typography>
            <Typography variant="h5" gutterBottom>
                Forbidden
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Không có quyền truy cập
            </Typography>
            <Box mt={4}>
                <Button variant="contained" color="primary" onClick={handleGoBack} size="large">
                    Về trang chủ
                </Button>
            </Box>
        </Container>
    );
};

export default Page403;
