import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./authContext";

const ProtectedRoute = ({ allowedRoles }) => {
  const { isAuthenticated, role, logout } = useAuth();

  if (!isAuthenticated || (allowedRoles && !allowedRoles.includes(role))) {
    localStorage.clear();
    logout();
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;