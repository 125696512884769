import React from "react";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const LoadingComponent = () => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: "all .15s linear",
      }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingComponent;
