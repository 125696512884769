export const validateRequired = (value, fieldName) => {
    if (!value || value.trim() === '') {
        return `${fieldName} không được để trống.`;
    }
    return null;
};

export const validateObject = (data, fieldNames) => {
    const errors = {};

    for(const [key, value] of Object.entries(data)){
        const fieldName = fieldNames[key] || key;
        const error = validateRequired(value, fieldName);
        if(error){
            errors[key] = error;
        }
    }

    return errors;
}

export const validateRePassword = (password = '', rePassword = '') => {
    if (password !== rePassword || rePassword.trim() === '') {
        return 'Mật khẩu không khớp, vui lòng nhập lại mật khẩu';
    }
    return null;
}

export const validateRegex = (pattern, value = '', message) =>{
    if(!pattern.test(value) && value.trim() !== '' ){
        return message;
    }
    return null;
}